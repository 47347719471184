<template>
  <div class="stageList">
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div class="search-group-wrap">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="账单编号">
                <el-input
                  v-model="searchForm.billNo"
                  placeholder="请输入订单编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="应还款日期">
                <el-date-picker
                  style="width: 100%"
                  v-model="repayment_date"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="serchData"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="账单状态">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.billStageStatus"
                  clearable
                  placeholder="请选择"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in billStageStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="一级商家">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.seniorId"
                  :remote-method="seniorRemoteMethod"
                  :loading="seniorLoading"
                  placeholder="请输入关键词"
                  clearable
                  filterable
                  remote
                  @clear="onSeniorClear"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in seniorList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="主体">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.entityId"
                  :remote-method="entityRemoteMethod"
                  :loading="entityLoading"
                  placeholder="请输入关键词"
                  clearable
                  filterable
                  remote
                  @clear="onEntityClear"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in entityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商家名称">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.businessId"
                  :remote-method="businessRemoteMethod"
                  :loading="businessLoading"
                  placeholder="请输入关键词"
                  clearable
                  filterable
                  remote
                  @clear="onBusinessClear"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in businessList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学员名称">
                <el-input
                  v-model="searchForm.username"
                  placeholder="请输入学员名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="电话">
                <el-input
                  v-model="searchForm.mobile"
                  placeholder="请输入电话"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="报价方式">
                <el-select
                  style="width: 95%"
                  v-model="xBusiness"
                  clearable
                  placeholder="请选择"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in xBusinessList"
                    :key="item.value + item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6"><el-form-item></el-form-item></el-col>
            <el-col :span="6"><el-form-item></el-form-item></el-col>
            <el-col :span="6"><el-form-item>
              <div style="float: right;">
              <el-button type="primary" size="small" @click="serchData"
                >搜索</el-button
              >
              <el-button size="small" @click="onExport">导出</el-button>
            </div>
            </el-form-item></el-col>  
          </el-row>
        </div>
      </el-form>
    </div>
    <base-table
      :columns="stageCol"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      :loading="loading"
      :isPaginationShow="true"
      wrapperHeight="calc(100% - 45px)"
    >
      <el-table-column
        slot="bill_no"
        label="账单编号"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="checkDetail(scope.row)"
            >{{scope.row.bill_no}}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        slot="should_platform_interest_business_payer"
        v-if="xBusiness"
        label="x期贴息金额"
        align="center"
        width="200">
        <template slot-scope="scope">
          <span>{{  scope.row.should_platform_interest_business_payer }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="current_stage" label="期数" align="center">
        <template slot-scope="scope">
          {{ scope.row.current_stage }}/{{ scope.row.total_stage_count }}
        </template>
      </el-table-column>
      <el-table-column slot="bill_stage_status" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.bill_stage_status == 1">未支付</span>
          <span v-if="scope.row.bill_stage_status == 2">未结清</span>
          <span v-if="scope.row.bill_stage_status == 3">已结清</span>
          <span v-if="scope.row.bill_stage_status == 4">已逾期</span>
        </template>
      </el-table-column>
      <el-table-column slot="payment_mode" label="还款模式" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.payment_mode == 0">当期支付</span>
          <span v-if="scope.row.payment_mode == 1">全部结清</span>
          <span v-if="scope.row.payment_mode == 2">部分结清</span>
          <span v-if="scope.row.payment_mode == 3">退课费结算</span>
        </template>
      </el-table-column>
    </base-table>
  </div>
</template>

<script>
import { selectSenior, selectEntity, selectBusiness } from "@/api/selectfun.js";
import columns from "./columns";
import { billStageRepaymentList } from "@/api/bill.js";
import config from "@/utils/config.js";

export default {
  name: "stageList",

  data() {
    return {
      ...columns,
      total: 0,
      loading: false,
      tableData: [],
      searchForm: {
        page: 1,
        per_page: 10,
        billStageStatus: 0,
      },
      seniorList: [],
      seniorLoading: false,
      entityList: [],
      entityLoading: false,
      businessList: [],
      businessLoading: false,
      billStageStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "未支付",
        },
        {
          value: 2,
          label: "未结清",
        },
        {
          value: 3,
          label: "已结清",
        },
        {
          value: 4,
          label: "已逾期",
        },
      ],
      xBusiness: '',
      xBusinessList: [
        {
          value: true,
          label: "x贴息订单",
        },
      ],
      repayment_date: [],
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  mounted() {
    this.setSelectTime();
    this.initData();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      if (this.repayment_date && this.repayment_date.length == 2) {
        this.searchForm.repaymentStartTime =
          this.repayment_date[0] + " " + "00:00:00";
        this.searchForm.repaymentEndTime =
          this.repayment_date[1] + " " + "23:59:59";
      } else {
        this.searchForm.repaymentStartTime = "";
        this.searchForm.repaymentEndTime = "";
      }
      let param = {...this.searchForm}
      if (this.xBusiness) {
        param.xBusiness = this.xBusiness
      }
      const { code, data } = await billStageRepaymentList(param);
      if (code == 200) {
        this.tableData = data.list;
        this.total = data.total;
      }
    },
    onExport() {
      let url = `${
        config.BASE_URL
      }/export/billStageRepaymentList?token=${localStorage.getItem("token")}&billNo=${
        this.searchForm.billNo || ""
      }&seniorId=${this.searchForm.seniorId || ""}&entityId=${
        this.searchForm.entityId || ""
      }&businessId=${this.searchForm.businessId || ""}&billStageStatus=${
        this.searchForm.billStageStatus || ""
      }&repaymentStartTime=${this.searchForm.repaymentStartTime || ""}&repaymentEndTime=${
        this.searchForm.repaymentEndTime || ""
      }`;
      if (this.xBusiness) {
        url = url + "&xBusiness=true"
      }
      window.open(url, "_blank");
    },
    checkDetail(row) {
      const routeData = this.$router.resolve({
        path: "/order/detail",
        query: {
          bill_no: row.bill_no,
        },
      });
      window.open(routeData.href, "_blank");
    },
    setSelectTime() {
      var _this = this;
      var date = new Date();
      var y = date.getFullYear();
      var m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

      var start_day = y + "-" + m + "-" + "01";
      var end_day = `${y}-${m}-${d}`;

      _this.repayment_date = [start_day, end_day];
      this.searchForm.repaymentStartTime = start_day + " " + "00:00:00";
      this.searchForm.repaymentEndTime = end_day + " " + "23:59:59";
    },
    seniorRemoteMethod(query) {
      if (query != "") {
        this.seniorLoading = true;
        setTimeout(() => {
          this.seniorLoading = false;
          this.getSelectSenior(query);
        }, 200);
      } else {
        this.seniorList = [];
      }
    },
    onSeniorClear() {
      this.searchForm.seniorId = "";
      this.seniorList = [];
      this.initData();
    },
    async getSelectSenior(query) {
      const param = {
        senior_name: query,
      };
      const { code, data } = await selectSenior(param);
      if (code == 200) {
        this.seniorList = data.map((item) => {
          item.value = item.senior_id;
          item.label = item.senior_name;
          return item;
        });
      }
    },
    entityRemoteMethod(query) {
      if (query != "") {
        this.entityLoading = true;
        setTimeout(() => {
          this.entityLoading = false;
          this.getSelectEntity(query);
        }, 200);
      } else {
        this.entityList = [];
      }
    },
    onEntityClear() {
      this.searchForm.entityId = "";
      this.entityList = [];
      this.initData();
    },
    async getSelectEntity(query) {
      const param = {
        entity_name: query,
      };
      const { code, data } = await selectEntity(param);
      if (code == 200) {
        this.entityList = data.map((item) => {
          item.value = item.entity_id;
          item.label = item.entity_name;
          return item;
        });
      }
    },
    businessRemoteMethod(query) {
      if (query != "") {
        this.businessLoading = true;
        setTimeout(() => {
          this.businessLoading = false;
          this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = [];
      }
    },
    onBusinessClear() {
      this.searchForm.businessId = "";
      this.businessList = [];
      this.initData();
    },
    async getSelectBusiness(query) {
      const param = {
        business_name: query,
      };
      const { code, data } = await selectBusiness(param);
      if (code == 200) {
        this.businessList = data.map((item) => {
          item.value = item.business_id;
          item.label = item.full_name;
          return item;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stagelist {
  .derive {
    margin-bottom: 10px;
  }
}
</style>